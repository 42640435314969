import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import SiriusIcon from '../../../../assets/images/favicon.png';
import GoogleIcon from './GoogleIcon';
import * as LinkJoy from '@mui/joy';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NameSpace } from '../../../../data/AppLanguage';
import Util from '../../../../utils/Util';
import AuthIdentity from '../../../services/AuthIdentity';
import UserLoginRequest from '../../../view-models/user/UserLoginRequest';
import { useState } from 'react';
import { ApiError } from '../../../models/ErrorModels/AuthErrorModel';
import UserResponse from '../../../view-models/user/UserResponse';
import TokenService from '../../../../services/api/TokenService';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
//   persistent?: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function SignIn() {
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const history = useHistory();
    const { t, i18n } = useTranslation([NameSpace.ROUTES]);
    const language = Util.splitLanguage(i18n.language, 0);
    
    const handleSubmit = async (event: React.FormEvent<SignInFormElement>) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;

        const data: UserLoginRequest = {
            email: formElements.email.value,
            password: formElements.password.value
        }

        try{
            const response = await AuthIdentity.postLogin(data);
            console.log(response.data);
            // setSuccessMessages();
            const successLogin: UserResponse = response.data;
            // alert(successLogin);
            console.log("REcebido: ", successLogin);
            TokenService.setAccessToken(successLogin);
            // Handle successful login
           history.push(`/${language}/dashboard`); // Redirect to dashboard or other page
        } catch (error) {
            if(error.response && error.response.data){
                const apiError: ApiError = error.response.data;
                setErrorMessages(apiError.errors.messages);
            } else {
                setErrorMessages(['An unexpected error occurred.']);
              }
        }
    };

    
      
  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '50vw' },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                <Link to={`/${language}`}>
                    <IconButton variant="soft" color="primary" size="sm">                
                        <img src={SiriusIcon}/>
                    </IconButton>
                </Link>
              <Typography level="title-lg">Sirius</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >

            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Entrar
                </Typography>
                <Typography level="body-sm">
                  Não tem uma conta?{' '}
                  <Link to={`/${language}/${t("signup")}`} level="title-sm">
                    <LinkJoy.Link>
                        Criar conta!
                    </LinkJoy.Link>                   
                  </Link>
                </Typography>
              </Stack>
              <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
              >
                Continue com Google
              </Button>
            </Stack>


            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                },
              })}
            >
              ou
            </Divider>


            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={handleSubmit}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>

                <FormControl required>
                  <FormLabel>Senha</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>

                {errorMessages.length > 0 && (
                <Box>
                    {errorMessages.map((message, index) => (
                    <p key={index} style={{ color: 'red' }}>{message}</p>
                    ))}
                </Box>
                )}

                <Stack gap={4} sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox size="sm" label="Lembrar-me" name="persistent" />
                    {/* <Link> */}
                        <LinkJoy.Link level="title-sm">
                            Esqueceu sua senha?
                        </LinkJoy.Link>
                    {/* </Link> */}                    
                  </Box>

                  <Button type="submit" fullWidth>
                    Entrar
                  </Button>

                  <Button onClick={() => history.push("/")} type="" color='warning' fullWidth>
                    Voltar
                  </Button>

                </Stack>

              </form>

            </Stack>
          </Box>


          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Sirius {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: '50vw' },
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
    </CssVarsProvider>
  );
}