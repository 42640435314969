
import UserLoginRequest from "../view-models/user/UserLoginRequest";
import UserRegisterRequest from "../view-models/user/UserRegisterRequest";
import { get, post } from "../../services/api/Api";

const endpoint = "v2/auth/";

const AuthIdentity = {
    postLogin: async (userLogin: UserLoginRequest) =>
        post<any>(`${endpoint}login`, JSON.stringify(userLogin),
        {
            headers: { "Content-Type": "application/json"},
        }
        ),

    postRegister: async (userRegister: UserRegisterRequest) =>       
        post<any>(`${endpoint}register`,  JSON.stringify(userRegister), 
        {
            headers: { "Content-Type": "application/json" },
        })
};

export default AuthIdentity;
