import axios, { AxiosError, AxiosResponse } from "axios";
import i18n, { t } from "i18next";

import { NameSpace, error } from "../../data/AppLanguage";

import Util from "../../utils/Util";
import { config } from "../../utils/Env";

import { AppDispatch } from "../../app/store";
import { setErrorMessage } from "../../features/error/errorSlice";
import TokenService from "./TokenService";
import { useHistory } from "react-router-dom";
import { navigateTo } from "./NavigationService";

const apiClient = axios.create({
  baseURL: config.url.API_URL,
  headers: { "Content-Type": "application/json" ,
},

  timeout: 100000,
});

const setupInterceptors = (history: any, dispatch: AppDispatch) => {

  apiClient.interceptors.request.use((response) => {
    const token = TokenService.getAccessToken();

    if(token){
      response.headers.Authorization = `Bearer ${token}`;
    }
    return response;
  });

  apiClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (axiosError: AxiosError) => {
      const language = Util.splitLanguage(i18n.language, 0);
      const path = `/${language}/${i18n.t("error", {
        ns: NameSpace.ROUTES,
      })}`;

      if (axiosError.response === undefined) {
        history.push(path, {
          statusCode: 500,
          message: i18n.t(`${error}errorOccur`, { ns: NameSpace.PAGES }),
        });
      } else if (axiosError.response.data instanceof Blob) {
        history.push(path, {
          statusCode: 500,
          message: i18n.t(`${error}fileErrorOccur`, { ns: NameSpace.PAGES }),
        });
      } else {
        const status = axiosError.response.status;

        if(status === 401){ // Token inválido ou expirado
          alert('Não autorizado! Necessita estar autenticado.')
          // const historyRedirect = useHistory();

          TokenService.removeAccessToken();
          // navigateTo(`/${language}/${i18n.t("signin", { ns: NameSpace.ROUTES })}`);
        }

        else if(status === 403){ // Token inválido ou expirado
          alert('Acesso negado! Necessita de elevação.')
        }

        else if (axiosError.response.config.method === "post") {
          const data = axiosError.response.data;
          let statusCode = data.statusCode ? data.statusCode : data.status;
          let message = data.message ? data.message : data.title;

          dispatch(
            setErrorMessage({
              statusCode: statusCode,
              message: message,
            })
          );
          setTimeout(() => {
            dispatch(setErrorMessage({ statusCode: 0, message: "" }));
          }, 5000);
        } else {
          history.push(path, axiosError.response.data);
        }
      }

      return Promise.reject(axiosError);
    }
  );
};

export default setupInterceptors;

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
