
import { CaseStudyModel } from "../../models/CaseStudyModel";
import { get } from "./Api";

const endpoint = "casestudy/";
// const endpoint = "v1/casestudy/";

const CaseStudy = {
    GetCaseStudys: async (language: string) =>
    get<CaseStudyModel[]>(endpoint, { params: { language } }),

    GetCaseStudy: async (path: string) =>
    get<CaseStudyModel>(`${endpoint}${path}`),
   
 
};

export default CaseStudy;
