import { CountryModel } from "../../models/CountryModel";

import { get } from "./Api";

const endpoint = "countries";
// const endpoint = "v1/countries";

const Country = {
  getCountries: async () => get<CountryModel[]>(endpoint),
};

export default Country;
