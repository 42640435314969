import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import DataSheetModelResponseToPDF from "../../models/DataSheetPDF/DataSheetModelResponseToPDF";
import htmlToText from 'html-to-text';
import html2canvas from 'html2canvas';
import { useEffect, useState } from "react";
import createTable from "../../pages/Variant/createtable";


const DataSheetPDfGenerator = ( dataSheet : DataSheetModelResponseToPDF, language?: string)  => {
    // const [image, setImage ] = useState("");
    const Roboto = "../../assets/fonts/Roboto-Medium.ttf";
    const RobotoBold = "../../assets/fonts/Roboto-Bold.ttf";

    const pdf = new jsPDF({ orientation: 'landscape', compress: true});

    pdf.setProperties({
        title: "Sirius",
    });
    
    //     // Função para pré-carregar uma imagem a partir de uma URL
    const loadImage = async (url: string, type: string, x: number, y: number, width: number, height: number) => {
      
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Define a política de CORS
        img.src = url + "?not-from-cache-please";

        pdf.addImage(img, type, x, y, width, height);
    };

      


    // IMAGEM PRINCIPAL
    pdf.addImage("https://mmsirius.siriuspt.eu/assinaturas/logo_sirius_blue.png", 'JPEG', 5, 5, 15, 15);
    if(dataSheet.urlImgMain) {        
        loadImage(dataSheet.urlImgMain, 'JPEG', 2, 35, 150, 100).catch(error => console.error('Erro ao adicionar imagem principal ao PDF: ', error));
    }


    // IMGAGEM NÃO CONTRATUAL
    let imageContractual = "---" ;

    switch(language?.toUpperCase())
    {
        case 'PT' : imageContractual = '* imagem não contratual';
        break;

        case 'ES' : imageContractual = '* imagen no contractual';
        break;

        case 'EN' : imageContractual = '* non-contractual image';
        break;

        case 'FR' : imageContractual = '* image non contractuelle';
        break;
        
    };

    console.log('imagem contract: ', imageContractual)

    pdf.setTextColor(139, 147, 159);    
    pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
    pdf.addFont("Roboto-Bold.ttf", "Roboto-Bold", "bold");   
    pdf.setFontSize(7);    
    pdf.text(imageContractual, 41, 140);

    // TÍTULO 
    pdf.setTextColor(40, 70, 122);    
    pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
    pdf.addFont("Roboto-Bold.ttf", "Roboto-Bold", "bold");   
    pdf.setFontSize(19);    
    if(dataSheet.title) pdf.text(dataSheet?.title, 160, 48);





    //SUBTÍTULO
    pdf.addFileToVFS("Roboto-Medium.ttf", Roboto);
    pdf.addFont("Roboto-Medium.ttf", "Roboto-Medium", "bold"); 
    pdf.setTextColor(139, 147, 159);
    pdf.setFontSize(12);
    if(dataSheet.subtitle) pdf.text(dataSheet?.subtitle, 160, 53);





    //SUBTÍTULO 2    
    pdf.setTextColor(0, 0, 0);
    const startY = 58; // Posição vertical inicial do texto
    const margin = 10; // Margem para a direita
    const fontSize = 9; // Tamanho da fonte desejado
    pdf.setFontSize(fontSize);  
    if(dataSheet.subtitleText){
        const textLines = pdf.splitTextToSize(dataSheet?.subtitleText, 120);    
        textLines.forEach((line: any, index: number) => {
            pdf.text(line, 160, startY + (index * (fontSize / 2))); // Ajuste o espaçamento vertical conforme necessário
        });
    }
    




    // TITLE 1 (INFORMAÇÃO DO PRODUTO)
    pdf.setTextColor(52, 92, 141);
    pdf.setFontSize(15);
    if(dataSheet.title1) pdf.text(dataSheet?.title1, 160, 78);
    





    // PARÁGRAFO 1
    pdf.setTextColor(33, 37, 41);    
    // Função para remover a marcação HTML e converter em texto simples, mantendo o estilo de lista
    const convertHtmlToList = (html: string): string => {
        // Remove todas as tags HTML, exceto as tags de lista (<ul> e <li>)
        let plainText = html.replace(/<(?!\/?(ul|li))[^>]+>/g, '');

        // Substitui as tags de lista por seus equivalentes de texto (marcadores)
        plainText = plainText.replace(/<\/?ul>/g, '');
        plainText = plainText.replace(/<\/li>/g, '\n'); // Pula para uma nova linha no final de cada item
        plainText = plainText.replace(/<li>/g, ' • '); // Adiciona o marcador no início de cada item

        return plainText;
    };

    // Converte o HTML em texto simples mantendo o estilo de lista
    if(dataSheet.paragraph1){
        const plainTextParagraph1 = convertHtmlToList(dataSheet?.paragraph1);

        
        //    // Converte o HTML em texto simples
        //    const plainTextParagraph1 = htmlToText.fromString(dataSheet?.paragraph1, {
        //     wordwrap: 120 // Largura máxima de cada linha
        // });
        
        // Define o tamanho da fonte para o parágrafo 1
        const paragraphFontSize = 9; // Tamanho da fonte do parágrafo
        pdf.setFontSize(paragraphFontSize);
        
        // Divide o texto do parágrafo 1 em várias linhas para que caiba na página
        const paragraphLines = pdf.splitTextToSize(plainTextParagraph1, 120);
        
        // Adiciona cada linha do parágrafo 1 ao PDF
        paragraphLines.forEach((line: any, index: number) => {
            pdf.text(line, 160, 86 + (index * (paragraphFontSize / 2))); // Ajuste o espaçamento vertical conforme necessário
        });
        
    }






   //LISTA DE CORES

   // Adiciona as miniaturas das cores
   const maxImagesPerRow = 4; // Máximo de miniaturas por linha
   const imageWidth = 12; // Largura da miniatura
   const imageHeight = 16; // Altura da miniatura
   const marginX = 10; // Margem horizontal entre as miniaturas
   const marginY = 3; // Margem vertical entre as miniaturas
   let xPos = 41; // Posição horizontal inicial das miniaturas
   let yPos = 145; // Posição vertical inicial das miniaturas


   if(dataSheet.listColorWithMedia.length > 0){
    dataSheet.listColorWithMedia.forEach((color, index) => {
        if (color.url) {
            if (index > 0 && index % maxImagesPerRow === 0) {
                xPos = 41; // Reinicia a posição horizontal para a próxima linha
                yPos += imageHeight + marginY; // Ajusta a posição vertical para a próxima linha
            }
            pdf.addImage(color.url, 'JPEG', xPos, yPos, imageWidth, imageHeight);
            xPos += imageWidth + marginX; // Ajusta a posição horizontal para a próxima miniatura
        }
    });
   }



   //RODAPÉ
   pdf.setTextColor(139, 147, 159);    
    pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
    pdf.addFont("Roboto-Bold.ttf", "Roboto-Bold", "bold");   
    pdf.setFontSize(7);    
   pdf.text('www.sirius.com.pt', 140, 200);




   
   //#################################### SEGUNDA PÁGINA #########################################################
   
   pdf.addPage()




   //IMAGEM DE CORTE
   pdf.addImage("https://mmsirius.siriuspt.eu/assinaturas/logo_sirius_blue.png", 'JPEG', 5, 5, 15, 15);

   let technicalInformation = '';
   let model = '';
   switch(language?.toLocaleUpperCase())
   {
        case 'PT': 
            technicalInformation = 'INFORMAÇÃO TÉCNICA';
            model = 'Modelo';
            break;

        case 'EN': 
            technicalInformation = 'TECHNICAL INFORMATION';
            model = 'Model';
            break;

        case 'FR': 
            technicalInformation = 'INFORMATIONS TECHNIQUES';
            model = 'Modèle';
            break;

        case 'ES': 
            technicalInformation = 'INFORMACIÓN TÉCNICA';
            model = 'Modelo';
            break;

   }

    // TITLE 1 (INFORMAÇÃO DO PRODUTO)
    if(language){
      pdf.setTextColor(52, 92, 141);
      pdf.setFontSize(17);
      pdf.text(technicalInformation, 120, 30);
    }

        


   if(dataSheet.urlImgCut)
    {
        loadImage(dataSheet.urlImgCut, 'JPEG', 0, 50, 120, 100).catch(error => console.error('Erro ao adicionar imagem principal ao PDF: ', error));
    } 




    pdf.setTextColor(139, 147, 159);    
    pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
    pdf.addFont("Roboto-Bold.ttf", "Roboto-Bold", "bold");   
    pdf.setFontSize(7);    
    pdf.text(imageContractual, 45, 160);




   pdf.setTextColor(40, 70, 122);



   //TABELA DA FICHA TÉCNICA

    // Crie um conjunto de modelos únicos e traduções únicas
    const uniqueModels  = [`${model}`].concat(Array.from(new Set(dataSheet.dataTable.map(item => item.feature.model))));  //UniqueModels
    uniqueModels.sort((a, b) => Number(a) - Number(b));
    console.log('uniqyemodels', uniqueModels);

    const uniqueTranslations  = Array.from(new Set(dataSheet.dataTable.map(item => item.translations.find(t => t.language_id === language?.toLocaleUpperCase())?.name ?? '')));

    // Crie um mapa para armazenar os valores correspondentes a cada modelo e tradução
    const modelValueMap = new Map<string, Map<string, string>>();
    dataSheet.dataTable.forEach(item => {
        const model = item.feature.model;
        const translation = item.translations.find(t => t.language_id === language?.toLocaleUpperCase())?.name ?? '';
        const value = item.feature.value;
        
        if (!modelValueMap.has(model)) {
            modelValueMap.set(model, new Map<string, string>());
        }
        modelValueMap.get(model)?.set(translation, value);
    });

    // Função para obter o conteúdo da célula, incluindo a imagem, se aplicável

    const getCellValue = (model: string, translation: string, cell?: any): any => {
        const matchingItem = dataSheet.dataTable.find(item => item.feature.model === model && item.translations.some(t => t.language_id === language?.toLocaleUpperCase() && t.name === translation));
        if (matchingItem && (matchingItem.feature.id === "Mec-06" || matchingItem.feature.id === "Mir-06")) {
          
        } else {
            return modelValueMap.get(model)?.get(translation) ?? '';
        }
    };


    // Organize os valores em uma matriz bidimensional
    const tableData = uniqueTranslations.map(translation => {
        const rowData: any[] = [translation];
        uniqueModels.slice(1).forEach(model => {
            const cellValue = getCellValue(model, translation);
            rowData.push(cellValue);
        });
        return rowData;
    });


    autoTable(pdf, {
        head: [uniqueModels], // Use os modelos como cabeçalhos
            body: tableData, // Use as traduções e os valores como corpo da tabela
            startY: 58, // Posição inicial da tabela no PDF
            tableWidth: 150,
            margin: { left: 130 },
            theme: 'striped', // Estilo da tabela
            styles: { 
                overflow: 'linebreak',
                halign: 'center',
                fontSize: 7.5
            }, // Estilo para lidar com quebras de linha
            columnStyles: {
                0: { cellWidth: 40, halign: "left" }, // Defina a largura da primeira coluna, se necessário
            },               
            didDrawCell: (data) => {
                if (data.section === 'body' && data.row.index === 5 && data.column.index > 0) {
                    let imageFromModel = '';
                    uniqueModels.forEach((model, index) => {
                        // index++;
                        let locationIndexModel = '';
                        if(data.column.index === index)
                        {
                            locationIndexModel = model;
                            console.log("captureo os models", locationIndexModel)
                        }                        
                        dataSheet.dataTable.find((dataSheet, indexFeature) => {                            
                            if(dataSheet.feature.model === locationIndexModel && dataSheet.feature.id === 'Mir-06' || dataSheet.feature.id === 'Mec-06'){
                                imageFromModel = dataSheet.feature.value;
                                console.log('imprimindo o value de feature value', imageFromModel);
                            } 
                        })
                  })
                  var base64Img = `https://mmsirius.siriuspt.eu/Categories/Subfamilies/EnergyEfficiency/${imageFromModel}`;
                 pdf.addImage(base64Img, 'JPEG', data.cell.x + 7, data.cell.y + 1, 8, 4)
                }
              },
    })


    // REFERÊNCIA
    if(dataSheet.code){
        pdf.setTextColor(139, 147, 159); 
        pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
        pdf.addFont("Roboto-Bold.ttf", "Roboto-Bold", "bold");   
        pdf.setFontSize(8);    
        pdf.text(`Ref: ${dataSheet.code}`, 10, 200);
   }


   //TEXTO ABAIXO DA TABELA
    if(dataSheet.technicalInformation){
         //SUBTÍTULO 2    
         pdf.setTextColor(139, 147, 159); 
        const startY = 160; // Posição vertical inicial do texto
        const margin = 10; // Margem para a direita
        const fontSize = 7; // Tamanho da fonte desejado
        pdf.setFontSize(fontSize);         
            const textLines = pdf.splitTextToSize(`* ${dataSheet?.technicalInformation}`, 140);    
            textLines.forEach((line: any, index: number) => {
                if(index === 0) {pdf.text(line, 130, startY + (index * (fontSize / 2))); }
                else if (index > 0){ pdf.text(line, 132, startY + (index * (fontSize / 2))); }
            });        
    }

    if(dataSheet.subTitleFooter2){
         //SUBTÍTULO footer text 2    
         pdf.setTextColor(139, 147, 159); 
        const startY = 170; // Posição vertical inicial do texto
        const margin = 10; // Margem para a direita
        const fontSize = 7; // Tamanho da fonte desejado
        pdf.setFontSize(fontSize);         
            const textLines = pdf.splitTextToSize(`* ${dataSheet?.subTitleFooter2}`, 140);    
            textLines.forEach((line: any, index: number) => {
                if(index === 0) {pdf.text(line, 130, startY + (index * (fontSize / 2))); }
                else if (index > 0){ pdf.text(line, 132, startY + (index * (fontSize / 2))); }
            });        
   }
    
    pdf.setTextColor(139, 147, 159); 
    pdf.addFileToVFS("Roboto-Bold.ttf", RobotoBold)
    pdf.addFont("Roboto-Bold.ttf", "Roboto-Bold", "bold");   
    pdf.setFontSize(7);    
    pdf.text('www.sirius.com.pt', 140, 200);


    let name = `${dataSheet.title}.pdf` ?? "product_sirius.pdf";
    pdf.save(name);
}

export default DataSheetPDfGenerator;

