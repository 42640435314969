import { useEffect, useState } from "react";
import CheckTokenAndRedirect from "../../../utils/AuthUtils";
import Product from "../../models/Catalog/Product/Product";


const DashboardContainer = () => {
    
    
    CheckTokenAndRedirect();
    
    return(
        <>
        <h1>Dashboard Container Autenticado</h1>
            {/* <NavbarDashboard/> */}
            {/* <SidebarDashBoard/> */}
            {/* <ContentDashboard/>         */}
        </>
    );
};


export default DashboardContainer;