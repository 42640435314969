import { jwtDecode } from "jwt-decode";
import UserResponse from "../../e-commerce-part/view-models/user/UserResponse";

class TokenService {
      getAccessToken() {

        return localStorage.getItem("accessToken");
      }
      
      getRefreshToken() {
        return localStorage.getItem("refreshToken");
      }
    
      setAccessToken(token: UserResponse) { 
        localStorage.setItem("accessToken", token.accessToken.toString());
      }
     
      setRefreshToken(token: UserResponse) {
        localStorage.setItem("refreshToken", token.accessToken.toString()); // trocar para o refresh depois
      }
    
      removeAccessToken() {
        localStorage.removeItem("accessToken");
      }
    
      removeRefreshToken() {
        localStorage.removeItem("refreshToken");
      }
};

export default new TokenService();