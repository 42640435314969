import { LanguageModel } from "../../models/LanguageModel";

import { get } from "./Api";

const endpoint = "languages";
// const endpoint = "v1/languages";

const Language = {
  getLanguages: async () => get<LanguageModel[]>(endpoint),
};

export default Language;
