import SettingModel from "../../models/SettingsModel";
import { get } from "./Api";

const endpoint = "settings/";
// const endpoint = "v1/settings/";

const SettingService = {
    GetById: async (settingId: string) => 
        get<SettingModel>(`${endpoint}settings/${settingId}`),

    GetOpportunityById: async (settingId: string) => 
        get<SettingModel>(`${endpoint}settings/${settingId}`),
}

export default SettingService;