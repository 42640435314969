import { DepartmentModel } from "../../models/DepartmentModel";

import { get } from "./Api";

const endpoint = "departments";
// const endpoint = "v1/departments";

const Department = {
  getDepartments: async (language: string) =>
    get<DepartmentModel[]>(endpoint, { params: { language } }),
};

export default Department;
