import { Environment } from "../enums/Environment";

const dev = {
  url: {
    // API_URL: "https://localhost:44341/api/",
     API_URL: "https://api.siriuspt.eu/api/",
  },
};

const prod = {
  url: {
    API_URL: "https://api.siriuspt.eu/api/",
  },
};

export const config =
  process.env.NODE_ENV === Environment.DEVELOPMENT ? dev : prod;


  //------- Produção -------------
  // https://api.siriuspt.eu/api/
  // https://apistage.siriuspt.eu/api/
  //https://localhost:44341/api/  
  //https://localhost:44365/api/  