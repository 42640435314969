import { string } from "yup";
import { destroy, get, post, put } from "./Api";
import CustomerDataSheetByFamilyResponse from "../../models/CustomerDataSheetByFamilyResponse";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";
import ProductAcessory from "../../models/ProductModelResponse";

const endpointCustomer = "customerdatasheet/";
const endpointProductAcessory = "product/";
// const endpointCustomer = "v1/customerdatasheet/";
// const endpointProductAcessory = "v1/product/";

const CustomerDataSheetService = {    
    getCustomerDataSheetById: async (id: string, language: string) => 
            get<DataSheetModelResponse>(`${endpointCustomer}customerdatasheetbyid/${id}/${language}`),    

    getCustomerDataSheetsByFamily: async (id: string, language: string) => 
            get<CustomerDataSheetByFamilyResponse[]>(`${endpointCustomer}customerdatasheetfamily/${id}/${language}`),


    getProductsByDataSheetCode: async (dataSheetCode: string, language: string) =>
            get<ProductAcessory[]>(`${endpointProductAcessory}getproductbydatasheetcode/${dataSheetCode}/${language}`),
            
};

export default CustomerDataSheetService;