import { GalleryModel } from "../../models/GalleryModel";
import { GalleryItemModel } from "../../models/GalleryItemModel";

import { get } from "./Api";

const endpoint = `solutions`;
// const endpoint = `v1/solutions`;

const Solution = {
  getSolutions: async (language: string, rows?: number) =>
    get<GalleryModel[]>(endpoint, { params: { rows, language } }),

  getSolution: async (path: string, language: string) =>
    get<GalleryItemModel>(`${endpoint}/${path}`, { params: { language } }),
};

export default Solution;
