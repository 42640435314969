import { jwtDecode } from "jwt-decode"
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import TokenService from "../services/api/TokenService";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../data/AppLanguage";
import Util from "./Util";

interface JwtPayload {
    exp: number;    
  }

const isTokenExpired = (token: string): boolean => {
    try {
        const decoded = jwtDecode<JwtPayload>(token);
        const currentTime = Math.floor(Date.now() / 1000);

        return decoded.exp < currentTime; //Deve retornar false
    }catch (error) {

        return true;
    }
}



function CheckTokenAndRedirect(){
    const history = useHistory();

    const { t, i18n } = useTranslation([NameSpace.ROUTES]);
    const language = Util.splitLanguage(i18n.language, 0);

    useEffect(() => {
        const token = TokenService.getAccessToken();        

        if(token){
            if(isTokenExpired(token)){
                alert('Sua sessão expirou. Você será redirecionado para a página de login.');
                localStorage.removeItem('accessToken');
                history.push(`/${language}/${t("signin", { ns: NameSpace.ROUTES })}`);
            }
        } else {
            history.push(`/${language}/${t("signin", { ns: NameSpace.ROUTES })}`);
        }
    },[history])
};

export default CheckTokenAndRedirect;