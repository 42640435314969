import MostWantedModel from "../../models/MostWantedModel";
import { get } from "./Api";

const endpoint = "mostwanted/";
// const endpoint = "v1/mostwanted/";

const MostWantedService = {
    getMostwanteds: async(languageId: string) => 
        get<MostWantedModel[]>(`${endpoint}mostwanteds/${languageId}`),
}

export default MostWantedService;